import React, { Component } from 'react';
import ErrorComp from '../components/errorComp';


class App extends Component {
  render() {
    let f = {location: {state: {error: {message: '404 Page Not Found'}}}}
    return (
      <>
        <ErrorComp errorMsg={f} />
      </>
    );
  }
}

export default App;
